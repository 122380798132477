import 'moment/locale/th';
import React, { useState, useEffect } from 'react';
import { formatComma, tableWidth, getTheme } from 'helpers/helpers';
import callApi from 'helpers/callapi';
import moment from 'moment';
import _, { get, find, orderBy, uniqBy, filter } from 'lodash';
import { Link } from 'react-router-dom';
import { CloudDownloadOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';

// Components
import CONSTANTS from 'constants/constants';
import { Button, Table, Pagination, Modal, Row, Col, Select, message, Input, Upload, Radio, Spin } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { useLocation, useParams } from 'react-router';

import { downloadWithPrefix } from 'helpers/download';
import fetchCsv from 'helpers/fetch-csv';
import { filterLabelAndValue } from 'helpers/helpers';

// constants
import ROUNDS from 'constants/rounds';
import TCAS_STATUSES from 'constants/tcas_statuses';

const ORDER_STATUS = CONSTANTS.orderStatus;
const ORDER_LABEL = CONSTANTS.orderLabels;
const BANK = CONSTANTS.banks;

const Option = Select.Option;

moment.locale('th');

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default () => {
  const query = useQuery();
  const history = useHistory();
  const dispatch = useDispatch();
  const me = useSelector((state) => state.user);
  const schools = useSelector((state) => {
    return state.schools || [];
  });

  const enrollments = useSelector((state) => state.enrollments || []);

  const year = useSelector((state) => state.year || '');

  const [universities, setUniversities] = useState([]);

  const [loading, setLoading] = useState(false);

  const [studentCount, setStudentCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [enrollmentsByUniversities, setEnrollmentsByUniversities] = useState({});

  const [filterQuery, setFilterQuery] = useState({
    university: query.get('university_id') || 'all',
    round: 'all',
    status: 'all',
  });

  const decorateData = () => {
    if (enrollments && enrollments.length > 0) {
      const _enrollments = enrollments.map((enrollment) => {
        const round = enrollment.type.split('_')[0];
        return { ...enrollment, round: round === '41' || round === '42' ? '4' : round };
      });

      setEnrollmentsByUniversities(_.groupBy(_enrollments, 'university_id'));
      setUniversities(
        _.orderBy(_.uniqBy(_enrollments, 'university_id'), 'university_id').map((enrollment) => {
          return {
            university_id: enrollment.university_id,
            university_name: enrollment.university_name_th,
          };
        })
      );
    } else {
      setEnrollmentsByUniversities({});
      setUniversities([]);
    }
  };

  const fetchEnrollments = async () => {
    try {
      setLoading(true);
      const { url } = await callApi({
        url: `/schools/me/enrollments/${year}`,
      });

      if (url || enrollments) {
        let enrollmentData = await fetchCsv({ fullURL: url });
        dispatch({ type: 'UPDATE_ENROLLMENTS', data: enrollmentData });
      } else {
        dispatch({ type: 'UPDATE_ENROLLMENTS', data: [] });
      }
      setLoading(false);
    } catch (err) {
      dispatch({ type: 'UPDATE_ENROLLMENTS', data: [] });
      console.log(err);
    }
  };

  useEffect(() => {
    if (enrollments.length === 0 && year) {
      fetchEnrollments();
    }
  }, [year]);
  useEffect(() => {
    console.log(enrollments);
    decorateData(enrollments);
  }, [enrollments]);

  const onChangePage = (_page) => {
    setCurrentPage(_page);
    fetchEnrollments(_page);
  };

  const onUniversityChange = async (value) => {
    setFilterQuery({
      ...filterQuery,
      university: value,
    });
  };

  const onCourseChange = async (value) => {
    setFilterQuery({
      ...filterQuery,
      program: value,
      major: 'all',
      project: 'all',
    });
    setLoading(true);
    await fetchProgramProjects(value);
    setLoading(false);
  };

  const onSearch = async () => {
    setCurrentPage(1);
    fetchEnrollments(1);
  };

  const getFilterEnrollments = () => {
    let filterData = [];
    if (filterQuery.university === 'all') {
      filterData = _.flatten(_.values(enrollmentsByUniversities));
    } else {
      filterData = enrollmentsByUniversities[filterQuery.university] || [];
    }
    if (filterQuery.round !== 'all') {
      filterData = filterData.filter((enrollment) => enrollment.round === filterQuery.round);
    }
    if (filterQuery.status !== 'all') {
      filterData = filterData.filter((enrollment) => enrollment.tcas_status === filterQuery.status);
    }

    return filterData;
  };

  const onExportEnrollments = async () => {
    try {
      setLoading(true);

      const { url } = await callApi({
        url: `/schools/me/enrollments/${year}`,
      });
      downloadWithPrefix(url, `enrollments-${year}-`);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
      message.error(get(error, 'reason.message') || 'Unable to download, please contact admin.');
    }
  };

  const filteredEnrollments = getFilterEnrollments();

  let universityEnrollments = filteredEnrollments;
  universityEnrollments = _.orderBy(universityEnrollments, 'type');

  return (
    <div>
      <Spin spinning={loading} delay={0}>
        <div className="t-main">
          <h1>
            <div style={{ float: 'right' }}>
              <Button type="primary" icon={<CloudDownloadOutlined />} onClick={() => onExportEnrollments()}>
                นำออกข้อมูลการศึกษาต่อทั้งหมด
              </Button>
            </div>
            ข้อมูลการเข้าศึกษาต่อ
          </h1>

          <div className="sec-box">
            <div className="sec-form">
              <div className="col-25">
                <label>
                  มหาวิทยาลัย
                  <Select
                    showSearch
                    style={{ width: '100%' }}
                    value={get(filterQuery, 'university')}
                    size={'large'}
                    dropdownMatchSelectWidth={false}
                    onChange={onUniversityChange}
                    filterOption={filterLabelAndValue}
                  >
                    <Option key={'all'} value={'all'} label={'ทั้งหมด'}>
                      ทั้งหมด
                    </Option>

                    {universities.map((university) => {
                      return (
                        <Option
                          key={university.university_id}
                          value={university.university_id}
                          label={university.university_name}
                        >
                          {university.university_id}: {university.university_name}
                        </Option>
                      );
                    })}
                  </Select>
                </label>
              </div>

              <div className="col-25">
                <label>
                  รอบ
                  <div>
                    <Select
                      style={{ width: '100%' }}
                      size={'large'}
                      dropdownMatchSelectWidth={false}
                      onChange={(value) => setFilterQuery({ ...filterQuery, round: value })}
                      value={get(filterQuery, 'round')}
                    >
                      <Option key={'all'} value="all" label="รอบทั้งหมด">
                        รอบทั้งหมด
                      </Option>
                      {(ROUNDS || []).map((round, i) => {
                        return (
                          <Option key={i} value={round.value} label={round.label}>
                            {round.label}
                          </Option>
                        );
                      })}
                    </Select>
                  </div>
                </label>
              </div>
              <div className="col-25">
                <label>
                  สถานะในระบบ
                  <div>
                    <Select
                      style={{ width: '100%' }}
                      size={'large'}
                      dropdownMatchSelectWidth={false}
                      onChange={(value) => setFilterQuery({ ...filterQuery, status: value })}
                      value={get(filterQuery, 'status')}
                    >
                      <Option key={'all'} value="all" label="สถานะ">
                        ทั้งหมด
                      </Option>
                      {TCAS_STATUSES.map((status, i) => {
                        return (
                          <Option key={i} value={status.value} label={status.label}>
                            {status.label}
                          </Option>
                        );
                      })}
                    </Select>
                  </div>
                </label>
              </div>

              <div className="col-25 col-action">
                <a
                  className="btn-main"
                  onClick={() => {
                    setCurrentPage(1);
                    fetchEnrollments(1);
                  }}
                >
                  ค้นหาข้อมูล
                </a>
              </div>
            </div>
          </div>

          {(!universityEnrollments || universityEnrollments.length === 0) && (
            <table>
              <thead></thead>
              <tbody>
                <tr>
                  <th style={{ textAlign: 'center' }}>ไม่พบข้อมูลการรับสมัคร</th>
                </tr>
              </tbody>
            </table>
          )}
          {universityEnrollments && universityEnrollments.length > 0 && (
            <>
              <table className="t-table -students">
                <thead>
                  <tr>
                    <th>รหัสประจำตัวประชาชน</th>
                    {/* <th>ชื่อ - นามสกุล</th> */}

                    <th>รอบที่สมัคร</th>
                    <th>มหาวิทยาลัย</th>
                    <th>คณะ</th>

                    <th>สถานะในระบบ</th>
                  </tr>
                </thead>
                <tbody>
                  {universityEnrollments.map((enrollment, i) => {
                    // const currentCourse = find(currentCourses, {
                    //   program_id: enrollment.program_id,
                    // });
                    // const university = find(schools, {
                    //   university_id: get(currentCourse, 'university_id'),
                    const round = _.find(ROUNDS, { value: enrollment.round }) || {};
                    const status = find(TCAS_STATUSES, { value: enrollment.tcas_status }) || {};

                    return (
                      <tr key={i}>
                        <td>{enrollment.citizen_id}</td>
                        {/* <td>
                          {enrollment.first_name} {enrollment.last_name}
                        </td> */}
                        <td className="">
                          <div>
                            รอบ {enrollment.round} {round.label}
                          </div>
                        </td>
                        <td>{enrollment.university_name_th}</td>
                        <td>{enrollment.faculty_name_th}</td>

                        <td>{status.label}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>

              {/* <Pagination
                current={currentPage}
                onChange={onChangePage}
                defaultPageSize={20}
                total={studentCount}
                showSizeChanger={false}
              /> */}
            </>
          )}
        </div>
      </Spin>
    </div>
  );
};
