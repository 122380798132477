import React from 'react';

const Contact = () => {
  return (
    <div className="t-main">
      <h1>คู่มือการใช้งาน</h1>
      <div className="sec-box">
        <div className="grid">
          <a href="https://assets.mytcas.com/school/sc-help-0.jpg" target="_blank" rel="noopener noreferrer">
            <img src="https://assets.mytcas.com/school/sc-help-0.jpg" alt="การเข้าสู่ระบบ" className="rounded" />
          </a>

          <div>
            <h2>การเข้าสู่ระบบ</h2>
            <ol>
              <li>
                ต้องเข้าที่{' '}
                <strong>
                  <code>https://school.mytcas.com/login</code>
                </strong>
                <br />
                โดยพิมพ์ URL ให้ครบ มิฉะนั้นระบบจะส่งต่อไปที่เว็บ mytcas.com แทน
              </li>
              <li>
                กรอกข้อมูลเข้าสู่ระบบ
                <br />
                <strong>Email:</strong> อีเมลที่ได้รับจากผู้ดูแล
                <br />
                <strong>Password:</strong> รหัสผ่านที่ได้รับจากผู้ดูแล
                <br />
              </li>
              <li>
                อ่านข้อกำหนดและเงื่อนไข คลิกยอมรับ แล้วกดปุ่ม <strong>เข้าสู่ระบบ</strong>
              </li>
            </ol>
          </div>
        </div>

        <hr />

        <div className="grid">
          <a href="https://assets.mytcas.com/school/sc-help-1.jpg" target="_blank" rel="noopener noreferrer">
            <img src="https://assets.mytcas.com/school/sc-help-1.jpg" alt="การเข้าสู่ระบบ" className="rounded" />
          </a>
          <div>
            <h2>ภาพรวมสำหรับผู้ดูแล</h2>
            <ol>
              <li>ชื่อโรงเรียนต้นสังกัดที่ดูแล</li>
              <li>เมนู ภาพรวม สรุปผล จำนวนนักเรียนทั้งหมดที่ผ่านการคัดเลือกและมีสิทธิ์ศึกษาต่อในมหาวิทยาลัย</li>
              <li>สถานะยืนยันสิทธิ์ตามมหาวิทยาลัย</li>
              <li>รายชื่อมหาวิทยาลัย</li>
              <li>จำนวนนักเรียนที่ติดในแต่ละรอบการสมัคร</li>
              <li>จำนวนรวมนักเรียนที่ติดมหาวิทยาลัยหรือสถาบันนั้นๆ</li>
            </ol>
          </div>
        </div>

        <hr />
        <div className="grid">
          <a href="https://assets.mytcas.com/school/sc-help-2.jpg" target="_blank" rel="noopener noreferrer">
            <img src="https://assets.mytcas.com/school/sc-help-2.jpg" alt="ข้อมูลสถิติ" className="rounded" />
          </a>
          <div>
            <h2>ข้อมูลสถิติ</h2>
            <ol>
              <li>เมนู ค่าสถิติพื้นฐาน ภาพรวมค่าสถิติพื้นฐานแยกตามรายวิชาที่สอบของนักเรียนทั้งหมด</li>
              <li>
                ข้อมูลสถิติแยกตามรายวิชา ประกอบด้วย
                <ul>
                  <li>TGAT/TPAT </li>
                  <li>TGAT Skills</li>
                  <li>A-Level</li>
                </ul>
              </li>
              <li>ตารางแสดงผล</li>
              <li>นำออกข้อมูลสถิติการสอบทั้งหมด</li>
            </ol>
          </div>
        </div>

        <hr />
        <div className="grid">
          <a href="https://assets.mytcas.com/school/sc-help-3.jpg" target="_blank" rel="noopener noreferrer">
            <img src="https://assets.mytcas.com/school/sc-help-3.jpg" alt="ข้อมูลผลการสอบ" className="rounded" />
          </a>
          <div>
            <h2>ข้อมูลผลการสอบ</h2>
            <ol>
              <li>เมนู นักเรียน</li>
              <li>เมนู ข้อมูลการสอบ คะแนนสอบแยกตามรายชื่อและแยกตามรายวิชาที่สอบ</li>
              <li>
                ข้อมูลผลการสอบแยกตามรายวิชา ประกอบด้วย
                <ul>
                  <li>TGAT/TPAT</li>
                  <li>TGAT Skills</li>
                  <li>A-Level</li>
                </ul>
              </li>
              <li>ตารางแสดงผล</li>
              <li>นำออกข้อมูลผลการสอบทั้งหมด</li>
            </ol>
          </div>
        </div>

        <hr />
        <div className="grid">
          <a href="https://assets.mytcas.com/school/sc-help-4.jpg" target="_blank" rel="noopener noreferrer">
            <img src="https://assets.mytcas.com/school/sc-help-4.jpg" alt="ข้อมูลการเข้าศึกษาต่อ" className="rounded" />
          </a>
          <div>
            <h2>ข้อมูลการเข้าศึกษาต่อ</h2>
            <ol>
              <li>เมนู ข้อมูลการเข้าศึกษาต่อ แยกตามรายชื่อ / มหาวิทยาลัย / รอบการสมัครและสถานะการยืนยันสิทธิ์ในระบบ</li>
              <li>ระบบค้นหาแยกตามมหาวิทยาลัย / รอบการสมัคร / สถานะการยืนยันสิทธิ์ในระบบ</li>
              <li>ตารางแสดงผล</li>
              <li>นำออกข้อมูลการศึกษาต่อทั้งหมด</li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
