import 'moment/locale/th';
import React, { useState, useEffect } from 'react';
import { formatComma, tableWidth, getTheme } from 'helpers/helpers';
import callApi from 'helpers/callapi';
import moment from 'moment';
import { get, find, orderBy } from 'lodash';
import { Link } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';

// Components
import CONSTANTS from 'constants/constants';

import { Button, Table, Pagination, Modal, Row, Col, Select, message, Input, Radio, Spin } from 'antd';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { filterLabelAndValue } from 'helpers/helpers';

import AddModal from 'containers/Admin/AddModal';

const ORDER_STATUS = CONSTANTS.orderStatus;
const ORDER_LABEL = CONSTANTS.orderLabels;
const BANK = CONSTANTS.banks;

const Option = Select.Option;
const PAGESIZE = 40;

moment.locale('th');

export default () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [filterQuery, setFilterQuery] = useState({
    q: '',
    schoolCode: '',
  });
  const [admins, setAdmins] = useState([]);
  const [adminCount, setAdminCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [addModalVisible, setAddModalVisible] = useState(false);

  const [adminDetailModalVisible, setAdminDetailModalVisible] = useState(false);
  const [schoolSearchText, setSchoolSearchText] = useState('');
  const [selectedSchool, setSelectedSchool] = useState({});
  const [selectedAdmin, setSelectedAdmin] = useState();

  const [status, setStatus] = useState('all');
  const me = useSelector((state) => state.user);
  const year = useSelector((state) => state.year || '');

  const [schools, setSchools] = useState([]);
  let foundSchool = false;
  console.log(schools);

  // const [creditCount, setCreditCount] = useState()
  const fetchAdmins = async (page, currentFilter = {}) => {
    try {
      setLoading(true);

      const query = { page: page || 1, ...filterQuery, ...currentFilter };

      const { count, data: admins } = await callApi({
        url: '/users',
        query,
      });

      setAdmins(admins);
      setAdminCount(count);

      setLoading(false);
    } catch (error) {
      setAdmins([]);
      setLoading(false);
    }
  };
  const fetchSchools = async () => {
    setLoading(true);
    try {
      const { url } = await callApi({
        url: `/schools/all/${year}`,
      });
      const res = await fetch(url);
      const _schools = await res.json();
      console.log(_schools);
      // console.log(_schools.json());
      setSchools(
        _schools.map((school, i) => ({
          ...school,
          index: i,
        }))
      );

      // console.log(schools);
    } catch (err) {
      console.log(err);
    }

    setLoading(false);
  };

  useEffect(() => {
    fetchAdmins();
  }, []);

  useEffect(() => {
    if (year) {
      fetchSchools();
    }
  }, [year]);

  const onChangePage = (page) => {
    setCurrentPage(page);
    fetchAdmins(page);
  };

  const onSchoolChange = async (value, option) => {
    setFilterQuery({
      ...filterQuery,
      schoolCode: value,
    });
    // setSchoolSearchText('');
    setSelectedSchool(option);
    fetchAdmins(1, { schoolCode: value });
  };

  const onSearch = async () => {
    setCurrentPage(1);
    fetchAdmins(1);
  };

  return (
    <Spin spinning={loading} delay={0}>
      <div>
        <div className="t-main">
          <h1>
            <div style={{ float: 'right' }}>
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => {
                  setSelectedAdmin({ role: 'school' });
                  setAddModalVisible(true);
                }}
              >
                เพิ่มคนดูแลระบบ
              </Button>
            </div>
            คนดูแลระบบ
          </h1>

          <div className="sec-box">
            <h2>ค้นหานักเรียน</h2>
            <div className="sec-form">
              <div className="col-50">
                <label>อีเมล, ชื่อโรงเรียน</label>
                <Input
                  value={get(filterQuery, 'q')}
                  onChange={(e) =>
                    setFilterQuery({
                      ...filterQuery,
                      q: e.target.value,
                    })
                  }
                  onPressEnter={onSearch}
                  type="text"
                />
              </div>
              <div className="col-25">
                <label>ชื่อโรงเรียน/รหัสโรงเรียน</label>
                <Select
                  showSearch={true}
                  style={{ width: '100%', marginTop: 0 }}
                  value={get(filterQuery, 'school')}
                  size={'large'}
                  dropdownMatchSelectWidth={false}
                  onChange={onSchoolChange}
                  // value={get(filterQuery, 'university')}
                  filterOption={filterLabelAndValue}
                  onSearch={(text) => setSchoolSearchText(text)}
                  onSelect={() => setSchoolSearchText('')}
                >
                  <Option key={'all'} label={''} value={''}>
                    โรงเรียนทั้งหมด
                  </Option>
                  {schools.map((school, index) => {
                    if (schoolSearchText.length < 2 && index > 100) {
                      if (selectedSchool.value === school.value) {
                        foundSchool = true;
                      }
                      return null;
                    }

                    return (
                      <Option key={school.code} label={school.name} value={school.value} index={index}>
                        {school.name}
                      </Option>
                    );
                  })}
                  {selectedSchool.index > 100 && !foundSchool && schoolSearchText.length < 2 && (
                    <Option
                      key={selectedSchool.code}
                      label={selectedSchool.label}
                      value={selectedSchool.value}
                      index={selectedSchool.index}
                    >
                      {selectedSchool.label}
                    </Option>
                  )}
                </Select>
              </div>

              <div className="col-25 col-action">
                <a
                  className="btn-main"
                  onClick={() => {
                    setCurrentPage(1);
                  }}
                >
                  ค้นหาข้อมูล
                </a>
              </div>
            </div>
          </div>

          <table>
            <thead>
              <tr>
                <th>ชื่อ</th>
                <th>อีเมล</th>
                <th>เบอร์ติดต่อ</th>
                <th>รหัส</th>
                <th>โรงเรียน</th>
                <th>ตำแหน่ง</th>
              </tr>
            </thead>
            <tbody>
              {admins.map((admin) => {
                const school =
                  find(schools, {
                    code: admin.school_code,
                  }) || {};
                return (
                  <tr>
                    <td>{admin.name}</td>
                    <td>
                      <a
                        onClick={() => {
                          if (me.role !== 'super' && admin.role === 'super') {
                            return;
                          }
                          setSelectedAdmin(admin);
                          setAddModalVisible(true);
                        }}
                      >
                        {admin.email}
                      </a>
                    </td>
                    <td>{admin.telephone}</td>
                    <td>{admin.role === 'school' ? school.code : '-'}</td>
                    <td>{admin.role === 'school' ? school.name : '-'}</td>
                    <td>{admin.role}</td>
                    {/* <td>{admin.maxValue}</td> */}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        <Pagination
          current={currentPage}
          onChange={onChangePage}
          defaultPageSize={PAGESIZE}
          total={adminCount}
          showSizeChanger={false}
        />

        {addModalVisible && (
          <AddModal
            setIsVisible={setAddModalVisible}
            isVisible={addModalVisible}
            selectedAdmin={selectedAdmin}
            onClose={() => fetchAdmins(currentPage)}
            schools={schools}
          ></AddModal>
        )}
      </div>
    </Spin>
  );
};

const ContentBody = styled.div`
  background-color: white;
  border: 1px solid ${getTheme`colors.gray`};
  padding: 20px;
`;
