import 'moment/locale/th';
import React, { useState, useEffect } from 'react';
import { formatComma, tableWidth, getTheme } from 'helpers/helpers';
import callApi from 'helpers/callapi';
import moment from 'moment';
import { get, find } from 'lodash';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import randomstring from 'randomstring';
import { isMobilePhone } from 'validator';
import { filterLabelAndValue } from 'helpers/helpers';

// Components
import CONSTANTS from 'constants/constants';

import { Button, Table, Pagination, Modal, Row, Col, Select, Form, message, Input, Popconfirm } from 'antd';

const Option = Select.Option;

moment.locale('th');

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

export default ({ selectedAdmin = { role: 'school' }, setIsVisible, onClose, isVisible, schools }) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const [schoolSearchText, setSchoolSearchText] = useState('');
  const [selectedSchool, setSelectedSchool] = useState({});

  const createAdmin = async (values) => {
    try {
      setLoading(true);

      const requestBody = {
        name: values.name,
        email: values.email,
        password: values.password,
        telephone: values.telephone,
        school: selectedSchool.value,
      };

      let reqParams = {
        url: `/users`,
        method: 'post',
        body: requestBody,
      };
      if (selectedAdmin._id) {
        reqParams = {
          url: `/users/${selectedAdmin._id}`,
          method: 'put',
          body: requestBody,
        };
      }

      await callApi(reqParams);

      setLoading(false);
      setIsVisible(false);
      onClose();
      // fetchOrders(currentPage);
    } catch (error) {
      console.error('Error while trying to fetch orders.', error);
      message.error(get(error, 'reason.message'));
      setLoading(false);
    }
  };

  const deleteUser = async () => {
    setLoading(true);
    await callApi({
      url: `/users/${selectedAdmin._id}`,
      method: 'delete',
    });
    setLoading(false);
    setIsVisible(false);
    onClose();
  };

  const validatePhone = async (rule, value) => {
    // check failing condition
    // if (isMobilePhone)
    if (!isMobilePhone(value, 'th-TH')) {
      throw new Error(`เบอร์โทรศัพท์ไม่ถูกต้อง`);
    }
  };

  const onSchoolChange = async (value, option) => {
    // setSchoolSearchText('');
    setSelectedSchool(option);
  };
  useEffect(() => {
    if (selectedAdmin.role === 'school' && selectedAdmin.school_code) {
      const index = _.findIndex(schools, { code: selectedAdmin.school_code });
      const school = schools[index];
      console.log(schools, school);

      const _selectedSchool = {
        code: selectedAdmin.school_code,
        label: school.name,
        value: selectedAdmin.school_code,
        index: index,
      };
      console.log(_selectedSchool);
      setSelectedSchool(_selectedSchool);
    }
  }, []);

  let foundSchool = false;

  return (
    <Modal
      title={`เพิ่มผู้ดูแลระบบ`}
      visible={isVisible}
      // okButtonProps={{ loading: loading }}
      width={800}
      footer={[
        <Button key="back" onClick={() => setIsVisible(false)}>
          ยกเลิก
        </Button>,
        <Button key="submit" type="primary" loading={loading} onClick={() => form.submit()}>
          {selectedAdmin._id ? 'แก้ไขผู้ดูแลระบบ' : 'สร้างผู้ดูแลระบบ'}
        </Button>,
        selectedAdmin._id ? (
          <Popconfirm
            onConfirm={() => {
              deleteUser();
            }}
            title={'คุณต้องการจะลบผู้ใช้คนนี้ออกจากระบบ'}
            okText="ใช่"
            cancelText="ไม่ใช่"
          >
            <Button key="delete" type="danger" loading={loading} style={{ float: 'left' }}>
              ลบผู้ดูแลระบบ
            </Button>
          </Popconfirm>
        ) : (
          <span></span>
        ),
      ]}
      onCancel={() => setIsVisible(false)}
    >
      <Form {...layout} form={form} name="control-hooks" onFinish={createAdmin}>
        <Row>
          {/* <Col span={8} style={{ marginBottom: 25 }}>
          <label>Username</label>
        </Col> */}
          <Col span={16}>
            <Form.Item
              label="Email"
              name="email"
              rules={[{ required: true, message: 'จำเป็นต้องใส่ Email' }]}
              initialValue={selectedAdmin.email}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={16}>
            <Form.Item
              label="Name"
              name="name"
              rules={[{ required: true, message: 'จำเป็นต้องใส่ Name' }]}
              initialValue={selectedAdmin.name}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={16}>
            <Form.Item
              label="Password"
              name="password"
              rules={[
                {
                  required: !selectedAdmin._id,
                  message: 'จำเป็นต้องใส่ Password',
                },
              ]}
            >
              <Input.Password />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Button
              type="primary"
              style={{ marginLeft: 15 }}
              onClick={() => {
                form.setFieldsValue({ password: randomstring.generate(8) });
              }}
            >
              Generate
            </Button>
          </Col>
          <Col span={16}>
            <Form.Item
              label="เบอร์มือถือ"
              name="telephone"
              initialValue={selectedAdmin.telephone}
              rules={[
                {
                  validator: validatePhone,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>

          {selectedAdmin.role === 'school' && (
            <Col span={16}>
              <Form.Item
                label="โรงเรียน"
                name="school"
                rules={[{ required: true, message: 'จำเป็นต้องใส่ โรงเรียน' }]}
                initialValue={selectedAdmin.school_code}
              >
                <Select
                  showSearch
                  filterOption={filterLabelAndValue}
                  dropdownMatchSelectWidth={false}
                  value={selectedSchool.code}
                  initialValue={selectedAdmin.school_code}
                  onChange={onSchoolChange}
                  onSearch={(text) => setSchoolSearchText(text)}
                  onSelect={() => setSchoolSearchText('')}
                >
                  {schools.map((school, index) => {
                    if (schoolSearchText.length < 2 && index > 100) {
                      if (selectedSchool.value === school.value) {
                        foundSchool = true;
                      }
                      return null;
                    }

                    return (
                      <Select.Option key={school.code} label={school.name} value={school.value} index={index}>
                        {school.code}: {school.name}
                      </Select.Option>
                    );
                  })}

                  {console.log(selectedSchool.index > 100, !foundSchool, schoolSearchText.length < 2)}

                  {selectedSchool.index > 100 && !foundSchool && schoolSearchText.length < 2 && (
                    <Option
                      key={selectedSchool.code}
                      label={selectedSchool.label}
                      value={selectedSchool.value}
                      index={selectedSchool.index}
                    >
                      {selectedSchool.value}: {selectedSchool.label}
                    </Option>
                  )}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Row>

        <div></div>
      </Form>
    </Modal>
  );
};
