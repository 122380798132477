module.exports = {
  TGATTPAT: [
    { value: 'ความถนัดทั่วไป (TGAT)', label: 'TGAT' },
    { value: 'การสื่อสารภาษาอังกฤษ (TGAT1)', label: 'TGAT1' },
    { value: 'การคิดอย่างมีเหตุผล (TGAT2)', label: 'TGAT2' },
    { value: 'สมรรถนะการทำงาน (TGAT3)', label: 'TGAT3' },
    { value: 'ความถนัดศิลปกรรมศาสตร์ (TPAT2)', label: 'TPAT2' },
    { value: 'ทัศนศิลป์ (TPAT21)', label: 'TPAT21' },
    { value: 'ดนตรี (TPAT22)', label: 'TPAT22' },
    { value: 'นาฏศิลป์ (TPAT23)', label: 'TPAT23' },
    { value: 'ความถนัดวิทยาศาสตร์ เทคโนโลยี วิศวกรรมศาสตร์ (TPAT3)', label: 'TPAT3' },
    { value: 'ความถนัดสถาปัตยกรรมศาสตร์ (TPAT4)', label: 'TPAT4' },
    { value: 'ความถนัดครุศาสตร์-ศึกษาศาสตร์ (TPAT 5)', label: 'TPAT5' },
  ],
  TGATSKILL: [
    { value: 'ทักษะการพูด (Speaking Skill)', label: 'ทักษะการพูด' },
    { value: 'ทักษะการอ่าน (Reading Skill) ', label: 'ทักษะการอ่าน' },
    { value: 'ความสามารถทางภาษา', label: 'ความสามารถทางภาษา' },

    {
      value: 'ความสามารถทางตัวเลข',
      label: 'ความสามารถทางตัวเลข',
    },
    {
      label: 'ความสามารถทางมิติสัมพันธ์',
      value: 'ความสามารถทางมิติสัมพันธ์',
    },
    { label: 'ความสามารถทางเหตุผล', value: 'ความสามารถทางเหตุผล', default: '' },
    { label: 'การสร้างคุณค่าและนวัตกรรม', value: 'การสร้างคุณค่าและนวัตกรรม', default: '' },
    { label: 'การแก้ไขปัญหาที่ซับซ้อน', value: 'การแก้ไขปัญหาที่ซับซ้อน', default: '' },
    { label: 'การบริหารจัดการอารมณ์', value: 'การบริหารจัดการอารมณ์', default: '' },
    { label: 'การเป็นพลเมืองที่มีส่วนร่วมของสังคม', value: 'การเป็นพลเมืองที่มีส่วนร่วมของสังคม', default: '' },
  ],
  ALEVEL: [
    {
      value: 'A-Level คณิตศาสตร์ประยุกต์ 1 (พื้นฐาน+เพิ่มเติม)',
      label: 'Math1',
    },
    { value: 'A-Level คณิตศาสตร์ประยุกต์ 2 (พื้นฐาน)', label: 'Math2' },
    { value: 'A-Level วิทยาศาสตร์ประยุกต์', label: 'Sci' },
    { value: 'A-Level ฟิสิกส์', label: 'Phy' },
    { value: 'A-Level เคมี', label: 'Chem' },
    { value: 'A-Level ชีววิทยา', label: 'Bio' },
    { value: 'A-Level สังคมศึกษา', label: 'Soc' },
    { value: 'A-Level ภาษาไทย', label: 'Thai' },
    { value: 'A-Level ภาษาอังกฤษ', label: 'Eng' },
    { value: 'A-Level ภาษาฝรั่งเศส', label: 'Fre' },
    { value: 'A-Level ภาษาเยอรมัน', label: 'Ger' },
    { value: 'A-Level ภาษาญี่ปุ่น', label: 'Jap' },
    { value: 'A-Level ภาษาเกาหลี', label: 'Kor' },
    { value: 'A-Level ภาษาจีน', label: 'Chi' },
    { value: 'A-Level ภาษาบาลี', label: 'Bal' },
    { value: 'A-Level ภาษาสเปน', label: 'Spn' },
  ],
};
