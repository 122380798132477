import Papa from 'papaparse';

export default async ({ fullURL }) => {
  try {
    const response = await fetch(fullURL);
    if (response.status !== 200) {
      return Promise.reject({ statusCode: response.status, reason: `Error fetching CSV` }); // eslint-disable-line
    }
    const csvData = await response.text();

    return new Promise((resolve, reject) => {
      Papa.parse(csvData, {
        header: true,
        complete: (results) => resolve(results.data),
        error: (error) => reject(error),
      });
    });
  } catch (error) {
    throw new Error(`Error fetching CSV: ${error.message}`);
  }
};
