import React, { useState, useRef, useEffect } from 'react';
import Timer from 'components/TimerOtp';

import { Button, Modal, Spin } from 'antd';

const OTPInput = ({ isVisible, setIsVisible, onSubmit, refCode, expiry, errorMessage, loading }) => {
  const [otp, setOtp] = useState(new Array(6).fill(''));
  const inputsRef = useRef([]);

  const handleChange = (element, index) => {
    const value = element.value;
    // Only update if the value is a digit or empty (for backspace)
    if (value === '' || /^[0-9]$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      // Move to next input if value is a digit
      if (value && index < otp.length - 1) {
        inputsRef.current[index + 1].focus();
      }
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pasteData = e.clipboardData.getData('text').slice(0, 6).replace(/\D/g, ''); // Get pasted text, limit to 6 characters, remove non-digits
    const newOtp = [...otp];

    // Split pasted text into individual characters and distribute across input fields
    pasteData.split('').forEach((char, index) => {
      if (index < otp.length) {
        newOtp[index] = char;
        if (inputsRef.current[index]) {
          inputsRef.current[index].value = char; // Directly set value for controlled component
        }
      }
    });

    setOtp(newOtp); // Update state with new OTP values
    // Focus the next input after the last pasted character, or the last input if all are filled
    const nextIndex = pasteData.length < otp.length ? pasteData.length : otp.length - 1;
    if (inputsRef.current[nextIndex]) {
      inputsRef.current[nextIndex].focus();
    }
  };

  const handleBackspace = (element, index) => {
    if (index > 0 && !element.value) {
      inputsRef.current[index - 1].focus();
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (otp.every((val) => val !== '')) {
      // Send OTP to parent component
      onSubmit(otp.join(''));
    } else {
      alert('Please fill all the OTP fields.');
    }
  };

  useEffect(() => {
    inputsRef.current[0].focus();
  }, []);

  return (
    <Modal
      visible={isVisible}
      // okButtonProps={{ loading: loading }}

      footer={null}
      onCancel={() => setIsVisible(false)}
    >
      <Spin spinning={loading}>
        <div>
          <form onSubmit={handleSubmit}>
            {errorMessage && (
              <div style={{ textAlign: 'center', color: 'red', marginBottom: '10px' }}>{errorMessage}</div>
            )}
            <div style={{ display: 'flex', justifyContent: 'center', margin: '40px 0px' }} onPaste={handlePaste}>
              {otp.map((data, index) => (
                <input
                  key={index}
                  type="text"
                  maxLength="1"
                  style={{ width: '40px', height: '40px', textAlign: 'center', marginRight: '6px' }}
                  value={data}
                  onChange={(e) => handleChange(e.target, index)}
                  onKeyDown={(e) => e.key === 'Backspace' && handleBackspace(e.target, index)}
                  ref={(ref) => (inputsRef.current[index] = ref)}
                />
              ))}
            </div>
            <div style={{ textAlign: 'center' }}>
              Reference Code: <strong>{refCode}</strong>
            </div>
            {expiry && (
              <div style={{ textAlign: 'center' }}>
                <Timer expiryTimestamp={expiry}></Timer>
              </div>
            )}

            <div style={{ textAlign: 'center', marginTop: '50px' }}>
              <Button
                type="primary"
                style={{ marginRight: 10 }}
                onClick={handleSubmit}
                disabled={!otp.every((val) => val !== '')}
              >
                ยืนยัน
              </Button>

              {/* <button type="submit" style={{ padding: '10px 20px' }}>
              
            </button> */}
            </div>
          </form>
        </div>
      </Spin>
    </Modal>
  );
};

export default OTPInput;
