const menus = [
  {
    key: 'dashboard',
    to: '/dashboard',
    title: 'ภาพรวม',
    roles: ['admin'],
  },
  {
    key: 'university',
    to: '/dashboard',
    title: 'ภาพรวม',
    roles: ['school'],
  },

  {
    key: 'stats',
    to: '/stats',
    title: 'ค่าสถิติพื้นฐาน',
    roles: ['school'],
  },

  {
    key: 'students',
    to: '/students/exams',

    title: 'นักเรียน',
    roles: ['school'],
    submenus: [
      {
        key: 'exam-results-list',
        to: '/students/exams',
        title: 'ข้อมูลการสอบ',
      },

      {
        key: 'enrollment',
        to: '/students/enrollments',
        title: 'ข้อมูลการศึกษาต่อ',
      },
    ],
  },

  {
    key: 'user',
    to: '/users',

    title: 'ผู้ดูแลระบบ',
    roles: ['admin'],
  },

  // {
  //   key: 'edit-user',
  //   to: '/users/edit',

  //   title: 'แก้ไขข้อมูลผู้ใช้',
  // },

  {
    key: 'help',
    to: '/help',
    title: 'คู่มือการใช้งาน',
  },
  {
    key: 'contact',
    to: '/contact',
    title: 'ติดต่อผู้ดูแลระบบ',
  },

  // {
  //   key: 'logs',
  //   to: '/logs',

  //   title: 'Logs',
  //   permission: 'log',
  // },
];
module.exports = menus;
