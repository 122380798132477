import 'moment/locale/th';
import React, { useState, useEffect } from 'react';

import callApi from 'helpers/callapi';
import { downloadCsvFromTable } from 'helpers/download';
import moment from 'moment';
import _, { get, find, orderBy, uniqBy } from 'lodash';

import { Tabs } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';

const { TabPane } = Tabs;

// Components
import CONSTANTS from 'constants/constants';

import { Button, Table, Pagination, Modal, Row, Col, Select, message, Input, Upload, Radio, Spin } from 'antd';
import { useHistory } from 'react-router-dom';

import fetchCsv from 'helpers/fetch-csv';

import SUBJECTS from 'constants/67/subjects';

// import AddModal from 'containers/Admin/AddModal';
// import AdminDetailModal from './AdminModal';

const ORDER_STATUS = CONSTANTS.orderStatus;
const ORDER_LABEL = CONSTANTS.orderLabels;
const BANK = CONSTANTS.banks;

const Option = Select.Option;
const TABS = [
  { label: 'TGAT/TPAT', value: 'TGATTPAT' },
  { label: 'TGAT Skills', value: 'TGATSKILL' },
  { label: 'A-Level', value: 'ALEVEL' },
];
const PAGESIZE = 40;
const tgatAlevelColumn = ['code', 'subject_name', 'max_score', 'n', 'mean', 'median', 'mode', 'sd', 'min', 'max'];
const tgatSkillColumn = [
  'code',
  'sub_code',
  'subject_name',
  'max_score',
  'n',
  'mean',
  'median',
  'mode',
  'sd',
  'min',
  'max',
];
const TEXT_RIGHT_COLUMNS = ['max_score', 'n', 'mean', 'median', 'mode', 'sd', 'min', 'max'];

moment.locale('th');

export default () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const me = useSelector((state) => state.user);

  // const loaded = useSelector((state) => state.coursesLoaded);

  const year = useSelector((state) => state.year || '');
  const [loading, setLoading] = useState(false);
  const [provinceStat, setProvinceStat] = useState([]);
  const [schoolStat, setSchoolStat] = useState([]);
  const [universeStat, setUniverseStat] = useState([]);
  const [schoolTypeStat, setSchoolTypeStat] = useState([]);

  const [schoolSearchText, setSchoolSearchText] = useState('');
  const [selectedSchool, setSelectedSchool] = useState({});
  const [selectedTab, setSelectedTab] = useState('TGATTPAT');

  let foundSchool = false;
  console.log(schoolSearchText, selectedSchool);

  const [filterQuery, setFilterQuery] = useState({
    q: '',
    citizen_id: '',
  });

  const fetchStat = async (page) => {
    try {
      setLoading(true);

      const { schoolUrl, provinceUrl, universeUrl, schoolTypeUrl } = await callApi({
        url: `/schools/me/exam/stats/${year}`,
      });

      if (schoolUrl) {
        const schoolData = await fetchCsv({ fullURL: schoolUrl });
        const provinceData = await fetchCsv({ fullURL: provinceUrl });
        const universeData = await fetchCsv({ fullURL: universeUrl });
        const schoolTypeData = await fetchCsv({ fullURL: schoolTypeUrl });
        setSchoolStat(schoolData || []);
        setProvinceStat(provinceData || []);
        setSchoolTypeStat(schoolTypeData || []);
        setUniverseStat(universeData || []);

        setLoading(false);
      }

      window.scrollTo(0, 0);
    } catch (error) {
      console.log('error', error);
      setSchoolStat([]);
      setProvinceStat([]);
      setSchoolTypeStat([]);
      setUniverseStat([]);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (year) {
      fetchStat();
    }
  }, [year]);

  useEffect(() => {
    if (me) {
      // fetchSchool();
    }
  }, [me]);

  const subjects = SUBJECTS[selectedTab];

  const onTabChange = (tab) => {
    setSelectedTab(tab);
  };

  const tableColumn = selectedTab === 'TGATSKILL' ? tgatSkillColumn : tgatAlevelColumn;

  return (
    <div>
      <Spin spinning={loading} delay={0}>
        <div className="t-main">
          <h1>
            ข้อมูลสถิติ
            <div style={{ float: 'right' }}>
              <Button
                type="primary"
                icon={<PlusOutlined />}
                style={{ marginRight: 10 }}
                onClick={() => downloadCsvFromTable('data-table', `stat-${selectedTab}-${me.school_code}`)}
              >
                นำออกข้อมูลนักเรียนทั้งหมด
              </Button>
            </div>
          </h1>

          <Tabs defaultActiveKey={selectedTab} type="card" style={{ marginBottom: 32 }} onChange={onTabChange}>
            {TABS.map((tab) => {
              return <TabPane type="card" tab={tab.label} key={tab.value}></TabPane>;
            })}
          </Tabs>

          {universeStat.length === 0 && (
            <table>
              <thead></thead>
              <tbody>
                <tr>
                  <th style={{ textAlign: 'center' }}>ไม่พบข้อมูลนักสถิติ</th>
                </tr>
              </tbody>
            </table>
          )}

          {universeStat.length > 0 && (
            <>
              <table className="t-table -students -vborder" id="data-table">
                <thead>
                  <tr>
                    <th>รหัสวิชา</th>
                    {selectedTab === 'TGATSKILL' && (
                      <>
                        <th>รหัสย่อย</th>
                      </>
                    )}
                    <th>วิชา</th>
                    <th>คะแนนเต็ม</th>
                    <th>จำนวนผู้เข้าสอบ (N)</th>
                    <th>คะแนนเฉลี่ย (Mean)</th>
                    <th>มัธยฐาน</th>
                    <th>ฐานนิยม</th>
                    <th>ส่วนเบี่ยงเบนมาตรฐาน</th>
                    <th>คะแนนต่ำสุด</th>
                    <th>คะแนนสูงสุด</th>
                  </tr>
                </thead>
                <tbody>
                  {subjects.map((subject, i) => {
                    // const currentCourse = find(currentCourses, {
                    //   program_id: student.program_id,
                    // });
                    // const university = find(schools, {
                    //   university_id: get(currentCourse, 'university_id'),
                    // });
                    const createBlankRow = (universeStat) => {
                      return {
                        ...universeStat,
                        max_score: '-',
                        n: '-',
                        mean: '-',
                        median: '-',
                        mode: '-',
                        sd: '-',
                        min: '-',
                        max: '-',
                      };
                    };

                    const universeStatBySubject = _.keyBy(universeStat, 'subject_name');
                    const universeStatToRender = universeStatBySubject[subject.value];

                    const provinceStatBySubject = _.keyBy(provinceStat, 'subject_name');
                    const provinceStatToRender = provinceStatBySubject[subject.value];

                    const schoolStatBySubject = _.keyBy(schoolStat, 'subject_name');
                    let schoolStatToRender = schoolStatBySubject[subject.value];

                    if (!schoolStatToRender && universeStatToRender) {
                      schoolStatToRender = createBlankRow(universeStatToRender);
                    }

                    const renderRow = (row, title) => {
                      if (row) {
                        return (
                          <tr
                            key={`i_${title}`}
                            className={cn({
                              'sub-row-stat': !!title,
                            })}
                          >
                            {tableColumn.map((col) => {
                              let text = row[col];
                              if (title) {
                                if (['code', 'sub_code'].indexOf(col) > -1) {
                                  text = '';
                                } else if (col === 'subject_name') {
                                  text = title;
                                }
                              }
                              return (
                                <>
                                  <td
                                    className={cn('no-wrap', {
                                      'text-right': TEXT_RIGHT_COLUMNS.indexOf(col) > -1,
                                      'sub-col-stat': !!title,
                                    })}
                                  >
                                    {text}
                                  </td>
                                </>
                              );
                            })}
                          </tr>
                        );
                      } else {
                        return <></>;
                      }
                    };
                    if (!schoolStatToRender) {
                      return <></>;
                    }
                    return (
                      <>
                        {renderRow(schoolStatToRender)}
                        {renderRow(universeStatToRender, 'ทั่วประเทศ')}
                        {renderRow(provinceStatToRender, 'จังหวัด')}
                      </>
                    );
                  })}
                </tbody>
              </table>
            </>
          )}
        </div>
      </Spin>
    </div>
  );
};
