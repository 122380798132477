module.exports = {
  download: (url) => {
    const a = document.createElement('a');
    a.href = url;
    a.download = url.split('/').pop();
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  },
  downloadWithPrefix: async (url, prefix) => {
    try {
      // Fetch the file
      const response = await fetch(url);
      if (!response.ok) throw new Error(`Failed to fetch file: ${response.statusText}`);

      // Get the file data
      const data = await response.blob();

      // Extract the filename and add the prefix
      const contentDisposition = response.headers.get('Content-Disposition');
      let fileName = url.split('/').pop();
      fileName = fileName.split('?').shift();

      console.log(fileName);
      const newFileName = prefix + decodeURIComponent(fileName);

      // Create a URL for the file data
      const objectURL = window.URL.createObjectURL(data);

      // Create an anchor element and download the file
      const a = document.createElement('a');
      a.href = objectURL;
      a.download = newFileName;
      document.body.appendChild(a);
      a.click();

      // Cleanup
      window.URL.revokeObjectURL(objectURL);
      document.body.removeChild(a);
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  },

  downloadCsvFromTable: (tableId = 'data-table', filename = 'table.csv') => {
    // Select rows from table ID
    let rows = document.querySelectorAll('table#' + tableId + ' tr');

    // Construct csv
    let csv = [];
    for (let i = 0; i < rows.length; i++) {
      let row = [],
        cols = rows[i].querySelectorAll('td, th');
      for (let j = 0; j < cols.length; j++) {
        // Clean the data by removing line breaks and extra spaces, and enclosing it in double quotes
        let data = cols[j].textContent.replace(/(\r\n|\n|\r)/gm, '').replace(/(\s\s+)/gm, ' ');
        data = data.replace(/"/g, '""'); // escape double quotes
        row.push('"' + data + '"');
      }
      csv.push(row.join(','));
    }

    // Create a link and download the CSV file
    let csvFile = new Blob([csv.join('\n')], { type: 'text/csv' });
    let downloadLink = document.createElement('a');
    downloadLink.download = filename;
    downloadLink.href = window.URL.createObjectURL(csvFile);
    downloadLink.style.display = 'none';
    document.body.appendChild(downloadLink);
    downloadLink.click();
  },
};
