import React, { useState, useEffect, useRef } from 'react';

import _, { get, map, reduce } from 'lodash';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';

// Helpers
import callApi from 'helpers/callapi';

// Component

// Hooks
import useMyUniversity from 'hooks/useMyUniversity';
import numeral from 'numeral';

import { Route, Switch, Redirect, useLocation, useHistory, Link } from 'react-router-dom';

import { UNIVERSITY_TYPES } from 'constants/constants';
import { Modal, Tooltip, Spin } from 'antd';
import fetchCsv from 'helpers/fetch-csv';
import { RedditSquareFilled } from '@ant-design/icons';

moment.locale('th');

// const UNIVERSITY_TYPES = TH.UNIVERSITY_TYPES;
const allRounds = [
  { name: 'Portfolio', index: 1 },
  { name: 'Quota', index: 2 },
  { name: 'Admission', index: 3 },
  { name: 'Direct Admission', index: 4 },
];

export default () => {
  // const [universities, setUniversities] = useState([]);
  const dispatch = useDispatch();
  const enrollments = useSelector((state) => state.enrollments || []);

  const year = useSelector((state) => state.year || '');
  const [loading, setLoading] = useState(false);

  const [enrollmentCountData, setEnrollmentCountData] = useState({
    countByGender: { นาย: 0, นางสาว: 0 },

    universityCount: 0,
    universityCountByTypes: { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 },

    countByRounds: { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 },
    countByStatuses: {},
    passCount: 0,
  });

  const [universities, setUniversities] = useState([]);
  const [enrollmentByUniversities, setEnrollmentByUniversities] = useState({});

  const decorateStat = (enrollmentData) => {
    setEnrollmentCountData({
      countByGender: _.countBy(enrollmentData, 'title'),

      universityCount: _.uniqBy(enrollmentData, 'university_id').length,
      universityCountByTypes: _.countBy(_.uniqBy(enrollmentData, 'university_id'), 'university_type_name_th'),

      countByRounds: _.countBy(enrollmentData, 'type'),

      countByStatuses: _.countBy(enrollmentData, 'tcas_status'),

      passCount: _.countBy(enrollmentData, 'tcas_status')['3'],
    });

    setUniversities(
      _.orderBy(_.uniqBy(enrollmentData, 'university_id'), 'university_id').map((enrollment) => {
        return {
          university_id: enrollment.university_id,
          university_name: enrollment.university_name_th,
        };
      })
    );
    const enrollmentCountByUniversitiesByRounds = _.reduce(
      _.groupBy(enrollmentData, 'university_id'),
      (results, enrollments, universityId) => {
        const rounds = enrollments.map((enrollment) => {
          const round = enrollment.type.split('_')[0];
          if (round === '41' || round === '42') return '4';
          return round;
        });
        results[universityId] = {
          total: enrollments.length,
          ..._.countBy(rounds),
        };

        return results;
      },
      {}
    );

    setEnrollmentByUniversities(enrollmentCountByUniversitiesByRounds);

    // setEnrollments(enrollmentData);
  };

  const fetchEnrollments = async () => {
    try {
      setLoading(true);
      const { url } = await callApi({
        url: `/schools/me/enrollments/${year}`,
      });

      if (url || enrollments) {
        if (enrollments.length === 0) {
          let enrollmentData = await fetchCsv({ fullURL: url });
          dispatch({ type: 'UPDATE_ENROLLMENTS', data: enrollmentData });
        }
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (enrollments.length === 0 && year) {
      fetchEnrollments();
    }
  }, [year]);
  useEffect(() => {
    decorateStat(enrollments);
  }, [enrollments]);

  if (enrollments.length === 0) return null;

  const formatGender = (g) => {
    if (g === 'นาย') return 'ผู้ชาย';
    return 'ผู้หญิง';
  };
  const formatStatus = (s) => {
    if (s === '3') return 'ยืนยันสิทธิ์';
  };

  return (
    <Spin spinning={loading} delay={0}>
      <div className="t-main">
        <h1>ภาพรวมสำหรับผู้ดูแล</h1>

        <div className="sec-dashboard">
          <div className="sec-box">
            <div className="title">
              <h3>นักเรียน</h3>
              <b>{enrollments.length}</b>
            </div>
            <div className="graph">
              <div className="t-scores">
                {map(enrollmentCountData.countByGender, (countByGender, key) => {
                  console.log(key, countByGender);
                  return (
                    <b
                      style={{
                        width: (countByGender / enrollments.length) * 100 + '%',
                      }}
                    ></b>
                  );
                })}
              </div>
              <table className="detail">
                <tbody>
                  {map(enrollmentCountData.countByGender, (countByGender, key) => {
                    return (
                      <tr>
                        <td>{formatGender(key)}</td>
                        <td>{numeral(countByGender).format('0,0')}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>

          <div className="sec-box">
            <div className="title">
              <h3>มหาวิทยาลัย</h3>
              <b>{numeral(enrollmentCountData.universityCount).format('0.0a')} </b>
            </div>
            <div className="graph">
              <div className="t-scores">
                {map(enrollmentCountData.universityCountByTypes, (countByType, key) => {
                  return (
                    <b
                      style={{
                        width: (countByType / enrollmentCountData.universityCount) * 100 + '%',
                      }}
                    ></b>
                  );
                })}
              </div>
              <table className="detail">
                <tbody>
                  {map(enrollmentCountData.universityCountByTypes, (countByType, key) => {
                    return (
                      <tr>
                        <td>{key}</td>
                        <td>{numeral(countByType).format('0,0')}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
          <div className="sec-box">
            <div className="title">
              <h3>ผ่านการคัดเลือก</h3>
              <b>{enrollmentCountData.passCount}</b>
            </div>
            <div className="graph">
              <div className="t-scores">
                {map(enrollmentCountData.countByRounds, (countByRound, key) => {
                  return (
                    <b
                      style={{
                        width: (countByRound / enrollmentCountData.passCount) * 100 + '%',
                      }}
                    ></b>
                  );
                })}
              </div>
              <table className="detail">
                <tbody>
                  {map(enrollmentCountData.countByRounds, (countByRound, key) => {
                    return (
                      <tr>
                        <td>รอบ {key.split('_')[0]}</td>
                        <td>{numeral(countByRound).format('0,0')}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
          <div className="sec-box">
            <div className="title">
              <h3>สถานะ</h3>
            </div>
            <div className="graph">
              <div className="t-scores">
                {map(enrollmentCountData.countByStatuses, (countByStatus, key) => {
                  return (
                    <b
                      style={{
                        width: (countByStatus / enrollments.length) * 100 + '%',
                      }}
                    ></b>
                  );
                })}
              </div>
              <table className="detail">
                <tbody>
                  {map(enrollmentCountData.countByStatuses, (count, key) => {
                    return (
                      <tr>
                        <td>{formatStatus(key)}</td>
                        <td>{numeral(count).format('0,0')}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <h2>สถานะยืนยันสิทธิ์ตามมหาวิทยาลัย</h2>

        <table className="t-table -ustatus">
          <thead>
            <tr>
              <th>รหัส</th>
              <th>ชื่อ</th>
              <th className="text-center">1. Portfolio</th>
              <th className="text-center">2. Quota</th>
              <th className="text-center">3. Admission</th>
              <th className="text-center whitespace-nowrap">4. Direct Admission</th>
              <th>ทั้งหมด</th>
            </tr>
          </thead>
          <tbody>
            {universities.map((university) => {
              return (
                <tr>
                  <td>{university.university_id}</td>
                  <td>{university.university_name}</td>
                  {allRounds.map((round) => {
                    return (
                      <td className="text-center">
                        {enrollmentByUniversities[university.university_id][round.index] || '-'}
                      </td>
                    );
                  })}
                  <td>
                    <Link
                      to={`/students/enrollments?university_id=${university.university_id}`}
                      className="btn-main -full whitespace-nowrap"
                    >
                      {enrollmentByUniversities[university.university_id]['total']} ราย
                      <i className="i-right"></i>
                    </Link>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </Spin>
  );
};
