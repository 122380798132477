import 'moment/locale/th';
import React, { useState, useEffect } from 'react';
import { formatComma, tableWidth, getTheme } from 'helpers/helpers';
import callApi from 'helpers/callapi';
import { downloadWithPrefix } from 'helpers/download';
import moment from 'moment';
import _, { get, find, orderBy, uniqBy } from 'lodash';
import { Link } from 'react-router-dom';
import { Tabs } from 'antd';
import { CloudDownloadOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';

const { TabPane } = Tabs;

// Components
import CONSTANTS from 'constants/constants';

import { Button, Table, Pagination, Modal, Row, Col, Select, message, Input, Upload, Radio, Spin } from 'antd';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { filterLabelAndValue, downloadFromUrl } from 'helpers/helpers';
import fetchCsv from 'helpers/fetch-csv';

import COLUMNS from 'constants/67/subjects';

// import AddModal from 'containers/Admin/AddModal';
// import AdminDetailModal from './AdminModal';

const ORDER_STATUS = CONSTANTS.orderStatus;
const ORDER_LABEL = CONSTANTS.orderLabels;
const BANK = CONSTANTS.banks;

const Option = Select.Option;
const TABS = [
  { label: 'TGAT/TPAT', value: 'TGATTPAT' },
  { label: 'TGAT Skills', value: 'TGATSKILL' },
  { label: 'A-Level', value: 'ALEVEL' },
];
const PAGESIZE = 40;

const download = (url, prefix) => {
  const a = document.createElement('a');
  a.href = url;
  a.download = url.split('/').pop();
  a.download = prefix + a.download;
  console.log(a.download);

  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

moment.locale('th');

export default () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const me = useSelector((state) => state.user);

  const schools = useSelector((state) => {
    return state.schools || [];
  });
  const courses = useSelector((state) => state.courses || {});
  const subjects = useSelector((state) => state.subjects || []);
  // const loaded = useSelector((state) => state.coursesLoaded);

  const year = useSelector((state) => state.year || '');
  const [loading, setLoading] = useState(false);
  const [students, setStudents] = useState([]);
  const [studentCount, setStudentCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [rounds, setRounds] = useState([]);
  const [schoolSearchText, setSchoolSearchText] = useState('');
  const [selectedSchool, setSelectedSchool] = useState({});
  const [selectedTab, setSelectedTab] = useState('TGATTPAT');

  let foundSchool = false;

  const [filterQuery, setFilterQuery] = useState({
    q: '',
    citizen_id: '',
  });

  const fetchSchool = async (university_id) => {
    try {
      const result = await callApi({
        url: '/schools/all',
      });
      dispatch({
        type: 'UPDATE_SCHOOLS',
        data: result,
      });
    } catch (error) {
      console.log('errror', error);
    }
  };

  const fetchStudents = async (page) => {
    console.log('fetch students');
    try {
      setLoading(true);

      const { url } = await callApi({
        url: `/schools/me/exam/students/${year}`,
      });

      if (url) {
        const data = await fetchCsv({ fullURL: url });
        setStudents(data || []);
        setStudentCount(data.length);
        setLoading(false);
        console.log(data);
      }

      window.scrollTo(0, 0);
    } catch (error) {
      console.log('error', error);
      setStudents([]);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (year) {
      fetchStudents(1, {});
    }
  }, [year]);

  useEffect(() => {
    if (me) {
      // fetchSchool();
    }
  }, [me]);

  const onChangePage = (_page) => {
    setCurrentPage(_page);
    // fetchStudents(_page);
  };

  const columns = COLUMNS[selectedTab];

  const onTabChange = (tab) => {
    setSelectedTab(tab);
  };

  const startIndex = (currentPage - 1) * PAGESIZE;
  const endIndex = startIndex + PAGESIZE;

  const displayStudents = students.slice(startIndex, endIndex);

  const onExportStduentExams = async () => {
    try {
      setLoading(true);

      const { url } = await callApi({
        url: `/schools/me/exam/students/${year}`,
      });
      downloadWithPrefix(url, `exam-${year}-`);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      message.error(get(error, 'reason.message') || 'Unable to download, please contact admin.');
    }
  };

  return (
    <div>
      <Spin spinning={loading} delay={0}>
        <div className="t-main">
          <h1>
            ข้อมูลผลการสอบ
            <div style={{ float: 'right' }}>
              <Button
                type="primary"
                icon={<CloudDownloadOutlined />}
                style={{ marginRight: 10 }}
                onClick={() => onExportStduentExams()}
              >
                นำออกข้อมูลผลการสอบทั้งหมด
              </Button>
            </div>
          </h1>

          <Tabs defaultActiveKey={selectedTab} type="card" style={{ marginBottom: 32 }} onChange={onTabChange}>
            {TABS.map((tab) => {
              return <TabPane type="card" tab={tab.label} key={tab.value}></TabPane>;
            })}
          </Tabs>

          {displayStudents.length === 0 && (
            <table>
              <thead></thead>
              <tbody>
                <tr>
                  <th style={{ textAlign: 'center' }}>ไม่พบข้อมูลผลสอบ</th>
                </tr>
              </tbody>
            </table>
          )}
          {displayStudents.length > 0 && (
            <>
              <table className="t-table -students">
                <thead>
                  <tr key="header">
                    <th key="cit">รหัสประจำตัวประชาชน</th>
                    {/* <th key="name">ชื่อ - นามสกุล</th> */}
                    {columns.map((column, i) => {
                      return <th key={i}>{column.label}</th>;
                    })}
                  </tr>
                </thead>
                <tbody>
                  {displayStudents.map((student, i) => {
                    // const currentCourse = find(currentCourses, {
                    //   program_id: student.program_id,
                    // });
                    // const university = find(schools, {
                    //   university_id: get(currentCourse, 'university_id'),
                    // });

                    return (
                      <tr key={i}>
                        <td>{student['รหัสประจำตัว']}</td>

                        {/* <td>
                          {student['ชื่อ']} {student['นามสกุล']}
                        </td> */}

                        {columns.map((column, j) => {
                          return <td key={j}>{student[column.value]}</td>;
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>

              <Pagination
                current={currentPage}
                onChange={onChangePage}
                defaultPageSize={PAGESIZE}
                total={studentCount}
                showSizeChanger={false}
              />
            </>
          )}
        </div>
      </Spin>
    </div>
  );
};
